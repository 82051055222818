import React from "react";
import { Box, Text, Heading, HStack, Center, Grid, GridItem, Button, IconButton, VStack, Input, Flex, Avatar, Badge, Tooltip, Tag, Divider, Spacer, Link} from "@chakra-ui/react";
import { SearchIcon, StarIcon, ArrowForwardIcon, InfoIcon } from '@chakra-ui/icons'
import {
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure
  } from '@chakra-ui/react'
  import {
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
  } from '@chakra-ui/react'
  import {
	List,
	ListItem,
	ListIcon,
	OrderedList,
	UnorderedList,
  } from '@chakra-ui/react'
  import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
  } from '@chakra-ui/react'
import moment from "moment";


function render_table_row_if_data_exists(label, data, list = false) {
    console.log(data)
	if(data !== undefined && data !== null && list == false) {
			return (      <Tr>
			  <Td>{ label }</Td>
			  <Td><Text align='left'>{ data }</Text></Td>
			</Tr>)
	}
	else if(data !== undefined && data !== null && list == true) {
			return (      <Tr>
			  <Td>{ label }</Td>
			  <Td><UnorderedList> { data.map((item) => <ListItem>  { item }  </ListItem>) } </UnorderedList></Td>
			</Tr>)
	}
	else {
			return ''
	}
  }



function offices_tooltip(offices) {

	if(offices !== null && offices !== 'undefined') {
		offices ="Minister of/responsible for: " + offices
		return (<Tooltip label={offices} aria-label={offices}><StarIcon boxSize="0.5em" color="gray.400" /></Tooltip>)
	}
	else {
		return ''
	}
}

function render_unknown_speaker(first, second, initial, last_name) {
	    let name = ''

	    if (first !== null) { name += first + " " }
	    if (second !== null) { name += second + " " }
	    if (initial !== null) {  name += initial + " " }
	    if (last_name !== null) { name += last_name + " "}
	    
	    return name
}

function DrawerExample({ props }) {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const btnRef = React.useRef()
  
  
	return (
	  <>
		<Button mt="20px" size='sm' variant='outline' rightIcon={<ArrowForwardIcon />} colorScheme='teal' ref={btnRef} colorScheme='teal' onClick={onOpen}>
		  Details
		</Button>
		<Drawer
		  isOpen={isOpen}
		  placement='right'
		  onClose={onClose}
		  finalFocusRef={btnRef}
		  size='lg'
		>
		  <DrawerOverlay />
		  <DrawerContent>
			<DrawerCloseButton />
			<DrawerHeader>
			</DrawerHeader>
  
			<DrawerBody>
		  
		  { props.summary  ? <Heading as='h4' size='sm' mb='10px'>Summary</Heading> : '' }
		  <Text mb='25px'>  { props.summary ? props.summary : '' }  </Text>
  
  
		  <Accordion defaultIndex={[0,1]} allowMultiple>
	<AccordionItem>
	  <h2>
		<AccordionButton>
		  <Box flex='1' textAlign='left'>
		  <Heading as='h4' size='sm' pb='5px'>Contents</Heading>
		  </Box>
		  <AccordionIcon />
		</AccordionButton>
	  </h2>
	  <AccordionPanel pb={4}>
  
  
  <TableContainer overflowX='visible' whiteSpace='wrap'>
	<Table variant='simple' size='sm'>
	  <Thead>
		<Tr>
		  <Th>Field</Th>
		  <Th>Value</Th>
		</Tr>
  
	  </Thead>
	  <Tbody>
  
	  { props.topics ? render_table_row_if_data_exists("Topics", props.topics, true) : '' }
  
	  { props.legislation ? render_table_row_if_data_exists("Legislation", props.legislation, true) : '' }
  
	  { props.tone ? render_table_row_if_data_exists("Tone", props.tone, true) : '' }
  
	  { props.sentiment ? render_table_row_if_data_exists("Sentiment", props.sentiment) : '' }
  
		<Tr>
		  <Td>Date</Td>
		  <Td>{ moment(props.date_of_debate, "YYYY-MM-DD").format("dddd, MMMM Do YYYY")}</Td>
		</Tr>
		<Tr>
		  <Td>Committee</Td>
		  <Td>  { props.committee_name }</Td>
		</Tr>
  
	  
  
  
	  </Tbody>
	  <Tfoot>
  
	  </Tfoot>
	</Table>
  </TableContainer>
  
  
  
	  </AccordionPanel>
	</AccordionItem>
  
	<AccordionItem>
	  <h2>
		<AccordionButton>
		  <Box flex='1' textAlign='left'>
		  <Heading as='h4' size='sm' pb='5px'>Speaker</Heading>
		  </Box>
		  <AccordionIcon />
		</AccordionButton>
	  </h2>
	  <AccordionPanel pb={4}>
  
  
  
	  <TableContainer overflowX='visible' whiteSpace='wrap'>
	<Table variant='simple' size='sm'>
	  <Thead>
		<Tr>
		  <Th>Field</Th>
		  <Th>Value</Th>
		</Tr>
  
	  </Thead>
	  <Tbody>
  
		{ render_table_row_if_data_exists('Speaker', props.name ? props.name : render_unknown_speaker(props.extracted_title_first, props.extracted_title_second, props.extracted_initials, props.extracted_last_name) ) }
  
		{ render_table_row_if_data_exists('Electoral Division', props.electoral_division) }
  
		{ render_table_row_if_data_exists('Party', props.party) }
  
		{/* render_table_row_if_data_exists('Minister of / Responsible for', props.offices_held_during, true) */}
  
	  </Tbody>
	  <Tfoot>
  
	  </Tfoot>
	</Table>
  </TableContainer>
	 
  
	  </AccordionPanel>
	</AccordionItem>
  </Accordion>
  { /* <Stack direction='row' spacing={4} mt='15px'>
			  <Link href={result.wheatpool_path.raw}><Button mt="20px" size="sm" colorScheme="teal">View in context</Button></Link>
			  <Link href={result.wheatpool_path.raw}><Button mt="20px" size="sm" colorScheme="teal">Download original PDF</Button></Link>
			  </Stack>
	*/ }
  
  
  
			</DrawerBody>
  
			<DrawerFooter>
  
			</DrawerFooter>
		  </DrawerContent>
		</Drawer>
	  </>
	)
  }

export default function MLASpeechBox(props) {


  return (
<Flex
  width={{ base: "95%", sm: "85%", md: "70%", lg:"50%" }}
  border="1px"
  p="20px"
  borderColor="gray.200"
  borderRadius={5}
  backgroundColor="white"
  id={props.sequence}
>
  <Avatar
    display={{ base: "none", md: "block" }}
    size="xl"
    src={"/mla_photos/" + props.member_id + ".jpg"}
  />
  <Box ml="3" width="100%">
    <Text fontWeight="bold" fontSize="2xl">
      {props.name
        ? props.name
        : render_unknown_speaker(
            props.extracted_title_first,
            props.extracted_title_second,
            props.extracted_initials,
            props.extracted_last_name
          )}{" "}
      <Badge> {props.party} </Badge>{" "}
      {offices_tooltip(props.offices_held_during)}
    </Text>
	<HStack>
        <Text fontWeight="bold" fontSize="md">
          {" "}
          {props.electoral_division}{" "}
        </Text>
        <Text fontSize="sm">
		 {moment(props.date, "YYYY-MM-DD").fromNow()}
        </Text>
      </HStack>
      <Tag mt="5px" size="sm" variant="outline" mb="10px">
        {props.committee_name}
      </Tag>
      <Divider />
      <Text
        dangerouslySetInnerHTML={{ __html: props.content }}
        fontSize="xl"
        pt="4px"
        pr="8px"
        whiteSpace="pre-line"></Text>
      <HStack>
        <Spacer />
        { props.sentiment ? <Link>
          <DrawerExample props={props} />
        </Link> : '' }
      </HStack>
  </Box>
</Flex>


  );
}
