import React from 'react';
import { render } from 'react-dom';
import { Link, Box, VStack, Heading, Text, HStack, Input, IconButton, Grid, GridItem, Button, Spinner } from "@chakra-ui/react";
import { SearchIcon } from '@chakra-ui/icons'
import MLASpeechBox from './MLASpeechBox';
import Hero from './Hero';



const Result = ({results}) => {

  let speeches = results.map((r, i) => <MLASpeechBox content={results[i].content} name={results[i].mla_full_name} date={results[i].hansard_date} member_id = {results[i].member_id}  committee_name={results[i].committee_name} party={results[i].party}  ></MLASpeechBox>);

  return speeches;


}

const Search = (props) => {
  const {
    searchQuery,
    onChange,
    search
  } = props;

  return (

<VStack align="center" spacing={8}>
  <Box
    p={8}
    width={{ base: "90%", md: "75%", lg: "60%" }}
    border="1px"
    borderColor="gray.200"
    borderRadius="lg"
    boxShadow="sm"
  >
    <Heading size="xl" mb={5} textAlign="center">Does your MLA care about the same things you do?</Heading>
    <Text fontSize="lg" textAlign="left" mb={8}>
Interested in what your MLA is up to in the legislature? Just enter your postal code and catch up on their latest conversations. No need to track down their name or your exact constituency — stepping into local politics is straightforward and instant.
    </Text>
    <HStack justifyContent="center">
      <Input
        value={searchQuery}
        onChange={onChange}
        width="auto"
        minWidth="250px"
        backgroundColor="white"
        placeholder="e.g. S4P1G4"
        size="md"
      />
      <IconButton
        onClick={search}
        size="md"
        colorScheme="blue"
        aria-label="Search database"
        icon={<SearchIcon />}
      />
    </HStack>
  </Box>
</VStack>

);
}

export class Container extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: '',
      results: [],
      loading: false
    }

    this.onSearchQueryChange = this.onSearchQueryChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  onSearchQueryChange(e) {
    this.setState({searchQuery: e.target.value});
  }

onSearch() {
  this.setState({ loading: true }); // Start loading
  const cleanedQuery = this.state.searchQuery.replace(/\s+/g, '').toUpperCase();

  const fetch_url = `https://api.wheatpool.ca/rpc/speech_by_postal?postal=${cleanedQuery}`;

  fetch(fetch_url)
    .then(response => response.json())
    .then(data => {
      this.setState({ results: data, loading: false }); // Stop loading
    })
    .catch((error) => {
      console.error('Error:', error);
      this.setState({ loading: false }); // Stop loading in case of error
    });
}

  render() {
  const { results, searchQuery, loading } = this.state;

  return (
    <Box>
      <Search
        searchQuery={searchQuery}
        onChange={this.onSearchQueryChange}
        search={this.onSearch}
        loading={loading} // Pass the loading state to the Search component
      />

      <Box backgroundColor="#FEFEFE" pt={1}>
        <VStack mt="40px" spacing="25px">
          {loading ? (
            <Spinner size="xl" thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" />
          ) : results.length > 0 ? (
            <>
              <Text fontSize="xl" width={{ base: "100%", md: "75%", lg: "50%" }} textAlign="center">
                  Here are the latest 10 contributions by your elected official that we have on record:
              </Text>
              <Result results={results} />
            </>
          ) : null}
        </VStack>
      </Box>
    </Box>
  );
  }
}
