import React, { useState, useEffect } from "react";
import {
  useParams,
  useLocation
} from "react-router-dom";
import MLASpeechBox from './MLASpeechBox';
import { Box, Text, Heading, Center, VStack, Spinner, Divider } from "@chakra-ui/react"
import { ChevronRightIcon } from '@chakra-ui/icons'
import moment from "moment";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function makeHansardPart(element) {
  if (element.content === '') {
    return '';
  }

  if (element.section_heading === true) {
    if (
      element.content === 'DEBATES' ||
      element.content === 'AND' ||
      element.content === 'PROCEEDINGS' ||
      element.content === 'LEGISLATIVE ASSEMBLY OF SASKATCHEWAN'
    ) {
      return '';
    }
    return <Box align='left' w={{ base: "75%", md: "50%" }}><Text fontSize='2xl' py={'15px'}><ChevronRightIcon />{element.content}<Divider py={'5px'} /></Text></Box>;
  }

  if (element.speaker_of_the_assembly !== null) {
    return (
      <MLASpeechBox
        content={element.content}
        name="The Speaker"
        date={element.date_of_debate}
        member_id={element.member_id}
        party={element.party}
        offices_held_during={element.offices_held_during}
        electoral_division={element.electoral_division}
        extracted_title_first={element.extracted_title_first}
        extracted_title_second={element.extracted_title_second}
        extracted_initials={element.extracted_initials}
        extracted_last_name={element.extracted_last_name}
        sequence={element.sequence}
        committee_name={element.committee_name}
        summary={element.summary}
        topics={element.topics}
        sentiment={element.sentiment}
        legislation={element.legislation}
        grants={element.grants}
        tone={element.tone}
      ></MLASpeechBox>
    );
  }

  return (
    <MLASpeechBox
      content={element.content}
      name={element.mla_full_name}
      date={element.date_of_debate}
      member_id={element.member_id}
      party={element.party}
      offices_held_during={element.offices_held_during}
      electoral_division={element.electoral_division}
      electoral_division={element.electoral_division}
      extracted_title_first={element.extracted_title_first}
      extracted_title_second={element.extracted_title_second}
      extracted_initials={element.extracted_initials}
      extracted_last_name={element.extracted_last_name}
      sequence={element.sequence}
      committee_name={element.committee_name}
      summary={element.summary}
      topics={element.topics}
      sentiment={element.sentiment}
      legislation={element.legislation}
      grants={element.grants}
      tone={element.tone}
    ></MLASpeechBox>
  );
}


export default function Hansard() {
        let query = useQuery();
	let id = useParams();
	let fetch_url = 'https://api.wheatpool.ca/rpc/debate?document_id=' + id['hansard_id']

    const [state, setState ] = useState({loading: 'true'})

    useEffect(() => {

fetch(fetch_url)
.then(response => response.json())

.then(data => {
  setState({results: data}, );

})

.catch((error) => {
  console.error('Error:', error);
});


    }, [])


let results = state['results']
const Result = ({results}) => {

  let speeches = results.map((r, i) => makeHansardPart(results[i]));


  return speeches;

}



  if ( state['loading'] === 'true' ) {
    return (<Center><Spinner
	      thickness="4px" speed="0.65s"
	      emptyColor="gray.200"
	      color="#86BA90"
	      size="xl"
	    /></Center>);
  }

  if (state['loading'] !== 'true' && query.get("seq") !== null) {
	 setTimeout(() => {document.getElementById(query.get("seq")).scrollIntoView() }, 1500);
         setTimeout(() => {document.getElementById(query.get("seq")).style.background = "#fdf6e3"; }, 1500);

  }
  

  return (
 
  <VStack mt="40px" spacing="25px" background="#fcfcfc" borderTop="1px" borderColor="gray.200" marginTop="-10px">
          <Box align="left" maxW="66%">
          <Heading size="xl" mt={10}>Debates and Proceedings of the Legislative Assembly of Saskatchewan</Heading>
          <Heading size="md">{ moment(results[0]['date_of_debate'], "YYYY-MM-DD").format("dddd, MMMM Do YYYY") }</Heading>
          </Box>
          
          <Result results={results} />
</VStack>


	  
  );
}
