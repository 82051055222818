import React from "react";
import { Link, Box, Spacer, Text, Heading, Tag, HStack, Center, Grid, GridItem, Button, IconButton, VStack, Input, Flex, Avatar, Badge, Tooltip, Divider, Stack} from "@chakra-ui/react";
import { InfoOutlineIcon, SearchIcon, StarIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure
} from '@chakra-ui/react'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import moment from "moment";


function generateMeetingURL(date, meetingType) {
  const meetingTypeIds = {
    "Crown and Central Agencies": "280140001",
    "Economy": "280140002",
    "House Services": "280140006",
    "Human Services": "280140003",
    "Intergovernmental Affairs and Justice": "280140004",
    "Public Accounts": "280140005",
    "Private Bills": "280140007",
    "Privileges": "280140008",
    "Board of Internal Economy": "280140009",
    "Prevent Abuse and Exploitation of Children": "280140017",
    "Agriculture": "280140011",
    "Communications": "280140012",
    "Estimates": "280140013",
    "Health Care": "280140014",
    "Non-controversial Bills": "280140018",
    "Regulations": "280140015",
    "Rules and Procedures": "280140016",
    "Tobacco Control": "280140019",
    "Traffic Safety": "280140010",
    "Legislative Assembly": ""
  };

  const meetingTypeId = meetingTypeIds[meetingType] || "";

  if (!date || !meetingType || meetingTypeId === undefined) {
    return null; // Return null for invalid inputs
  }

  const formattedDate = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
  const url = `https://www.legassembly.sk.ca/Archive?start=${formattedDate}&end=${formattedDate}&context=${meetingTypeId}&type=`;

  return url;
}

function RenderInfoIcon() {
  return (
    <Tooltip label="AI Generated" fontSize="md">
       <InfoOutlineIcon ml={1} w={3} h={3}/>
    </Tooltip>
  );
}



function offices_tooltip(offices) {

        if(offices !== null && offices !== 'undefined') {
                offices ="Minister of/responsible for: " + offices
                return (<Tooltip label={offices} aria-label={offices}><StarIcon boxSize="0.5em" color="#FFB833" /></Tooltip>)
        }
        else {
                return ''
        }
}

function render_unknown_speaker(first, second, initial, last_name) {
    let name = ''

    if (first !== null) { name += first + " " }
    if (second !== null) { name += second + " " }
    if (initial !== null) {  name += initial + " " }
    if (last_name !== null) { name += last_name + " "}
    
    return name
}

function render_table_row_if_data_exists(label, data, list = false, ai_generated = false) {
  if (ai_generated === false) {
    if (data !== undefined && data !== null && list === false) {
      return (
        <Tr>
          <Td>{label}</Td>
          <Td>
            <Text align="left">{data}</Text>
          </Td>
        </Tr>
      );
    } else if (data !== undefined && data !== null && list === true) {
      return (
        <Tr>
          <Td>{label}</Td>
          <Td>
            <UnorderedList>
              {data.map((item) => (
                <ListItem key={item}>{item}</ListItem>
              ))}
            </UnorderedList>
          </Td>
        </Tr>
      );
    } else {
      return null;
    }
  } else if (ai_generated === true) {
    if (data !== undefined && data !== null && list === false) {
      return (
        <Tr>
          <Td>
            {label}
            {RenderInfoIcon()} {/* Make sure RenderInfoIcon is defined */}
          </Td>
          <Td>
            <Text align="left">{data}</Text>
          </Td>
        </Tr>
      );
    } else if (data !== undefined && data !== null && list === true) {
      return (
        <Tr>
          <Td>
            {label}
            {RenderInfoIcon()} {/* Make sure RenderInfoIcon is defined */}
          </Td>
          <Td>
            <UnorderedList>
              {data.map((item) => (
                <ListItem key={item}>{item}</ListItem>
              ))}
            </UnorderedList>
          </Td>
        </Tr>
      );
    } else {
      return null;
    }
  }
}


function DrawerExample({ result }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()


  return (
    <>
      <Button mt="20px" size='sm' variant='outline' rightIcon={<ArrowForwardIcon />} colorScheme='teal' ref={btnRef} colorScheme='teal' onClick={onOpen}>
        Details
      </Button>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
        size='lg'
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
          </DrawerHeader>

          <DrawerBody>
        
        { result.summary && result.summary.raw ? <Heading as='h4' size='sm' mb='10px'>Summary {RenderInfoIcon()}</Heading> : '' }
        <Text mb='25px'>  { result.summary && result.summary.raw ? result.summary.raw : '' }  </Text>


        <Accordion defaultIndex={[0,1]} allowMultiple>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box flex='1' textAlign='left'>
        <Heading as='h4' size='sm' pb='5px'>Contents</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>


<TableContainer overflowX='visible' whiteSpace='wrap'>
  <Table variant='simple' size='sm'>
    <Thead>
      <Tr>
        <Th>Field</Th>
        <Th>Value</Th>
      </Tr>

    </Thead>
    <Tbody>

    { result.topics && result.topics.raw ? render_table_row_if_data_exists("Topics", result.topics.raw, true, true)  : '' }

    { result.legislation && result.legislation.raw ? render_table_row_if_data_exists("Legislation", result.legislation.raw, true, true) : '' }

    { result.tone && result.tone.raw ? render_table_row_if_data_exists("Tone", result.tone.raw, true, true) : '' }

    { result.sentiment && result.sentiment.raw ? render_table_row_if_data_exists("Sentiment", result.sentiment.raw, false, true) : '' }

      <Tr>
        <Td>Date</Td>
        <Td>{ moment(result.date_of_debate.raw, "YYYY-MM-DD").format("dddd, MMMM Do YYYY")}</Td>
      </Tr>
      <Tr>
        <Td>Committee</Td>
        <Td>  { result.committee_name.raw }</Td>
      </Tr>

    


    </Tbody>
    <Tfoot>

    </Tfoot>
  </Table>
</TableContainer>



    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box flex='1' textAlign='left'>
        <Heading as='h4' size='sm' pb='5px'>Speaker</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>



    <TableContainer overflowX='visible' whiteSpace='wrap'>
  <Table variant='simple' size='sm'>
    <Thead>
      <Tr>
        <Th>Field</Th>
        <Th>Value</Th>
      </Tr>

    </Thead>
    <Tbody>

      { render_table_row_if_data_exists('Speaker', result.mla_full_name.raw ? result.mla_full_name.raw : render_unknown_speaker(result.extracted_title_first.raw, result.extracted_title_second.raw, result.extracted_initials.raw, result.extracted_last_name.raw) ) }

      { render_table_row_if_data_exists('Electoral Division', result.electoral_division.raw) }

      { render_table_row_if_data_exists('Party', result.party.raw) }

      { render_table_row_if_data_exists('Minister of / Responsible for', result.offices_held_during.raw, true) }

    </Tbody>
    <Tfoot>

    </Tfoot>
  </Table>
</TableContainer>
   

    </AccordionPanel>
  </AccordionItem>
</Accordion>
<Stack direction='row' spacing={4} mt='15px'>
            <Link href={result.wheatpool_path.raw}><Button mt="20px" size="sm" colorScheme="teal">View in context</Button></Link>
            <Link href={generateMeetingURL(result.date_of_debate.raw, result.committee_name.raw)}><Button mt="20px" size="sm" colorScheme="teal">Access in Legislative Archive</Button></Link>
            </Stack>



          </DrawerBody>

          <DrawerFooter>

          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}






export default ({ result }) => (
<Box>
  <Flex
    width="100%"
    border="1px"
    p="20px"
    borderColor="gray.200"
    borderRadius={5}
    backgroundColor="white"
  >
    <Avatar
      display={{ base: "none", md: "block" }}
      size="xl"
      src={"/mla_photos/" + result.mla_id.raw + ".jpg"}
    />
    <Box ml="3" width="100%">
      <Text fontWeight="bold" fontSize="2xl">
        {result.mla_full_name.raw
          ? result.mla_full_name.raw
          : render_unknown_speaker(
              result.extracted_title_first.raw,
              result.extracted_title_second.raw,
              result.extracted_initials.raw,
              result.extracted_last_name.raw
            )}{" "}
        <Badge> {result.party.raw} </Badge>{" "}
        {offices_tooltip(result.offices_held_during.raw)}
      </Text>
      <HStack>
        <Text fontWeight="bold" fontSize="md">
          {" "}
          {result.electoral_division.raw}{" "}
        </Text>
        <Text fontSize="sm">
          {moment(result.date_of_debate.raw, "YYYY-MM-DD").fromNow()}
        </Text>
      </HStack>
      <Tag mt="5px" size="sm" variant="outline" mb="10px">
        {result.committee_name.raw}
      </Tag>
      <Divider />
      <Text
        dangerouslySetInnerHTML={{ __html: result.content.snippet }}
        fontSize="xl"
        pt="4px"
        pr="8px"
      ></Text>
      <HStack>
        <Spacer />
        <Link>
          <DrawerExample result={result} />
        </Link>
      </HStack>
    </Box>
  </Flex>
</Box>
);
