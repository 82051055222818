import React from "react";
import { Code, Box, Text, Heading, HStack, Center, Grid, GridItem, Button, IconButton, VStack, Input, Link, UnorderedList, ListItem } from "@chakra-ui/react";
import { SearchIcon } from '@chakra-ui/icons'
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"


export default function Api(props) {
  return (

<VStack align="center" spacing="10px">
<Box p={5} width="75%" border="1px" borderColor="gray.200" borderRadius="10px">
         <Box backgroundColor="#fdf6e3" p={5} borderRadius={10}>
	              <Heading size="md" py={3}>Welcome to the Wheatpool Data Labs API Reference.</Heading>
	              <Text fontSize="xl">All available endpoints and fields shown below are produced by the OpenAPI description served on the root path at api.wheatpool.ca</Text>
	              </Box>
	  <SwaggerUI url="https://api.wheatpool.ca" />
</Box>




</VStack>



	  
  );
}
