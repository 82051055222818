import React from 'react';
import { Box, Text, Center, useColorModeValue } from '@chakra-ui/react';

export default function Footer() {
  // Get the current year using JavaScript
  const year = new Date().getFullYear();

  // Optional: Use Chakra UI's useColorModeValue to adapt styles for light/dark mode
  const textColor = useColorModeValue('gray.600', 'gray.200');

  return (
    <Center as="footer" w="full" py={4}>
      <Box textAlign="center" color={textColor}>
        <Text fontSize="sm">Made with love in Regina, Saskatchewan.</Text>
        <Text fontSize="sm">© Wheatpool Data Labs {year}</Text>
	<Text fontSize="xs">This site is not affiliated with or endorsed by the Legislative Assembly of Saskatchewan.</Text>
      </Box>
    </Center>
  );
}
