import React from "react";
import { Box, Text, Heading, HStack, Center, Grid, GridItem, Button, IconButton, VStack, Input, Link, UnorderedList, ListItem, Image} from "@chakra-ui/react";
import { SearchIcon } from '@chakra-ui/icons'


export default function Contact(props) {
  return (

<VStack align="center" spacing="24px">

<Box p={5} width={{ base: "100%", md: "75%", lg: "50%" }} border="1px" borderColor="gray.200" borderRadius="10px">
  <Heading mb={4} size="xl">Join Us in Making a Difference</Heading>
  <Text fontSize="xl" mb={3}>Wheatpool Data Labs is a registered non-profit dedicated to enhancing democratic engagement. Our mission is fueled by passion, but sustained by the support of like-minded individuals and organizations.</Text>

<Text fontSize="md" mb={4}>If you believe in the power of technology to transform our democratic landscape in positive and meaningful ways and are interested in fostering the long-term growth of this initiative, we'd love to hear from you. Reach out to us at <em>{ "contact" + "@" + "wheatpool.ca" }</em>, and let's explore how we can collaborate for a brighter, more engaged future.</Text>

</Box>

</VStack>



	  
  );
}
