import React from "react";
import { Box, Text, Heading, Image, HStack, VStack, Center, SimpleGrid, Button, IconButton, Input, Link, UnorderedList, ListItem } from "@chakra-ui/react";
import { SearchIcon } from '@chakra-ui/icons'
import Hero2 from './Hero2';
import { RepeatIcon, BellIcon, CheckCircleIcon} from '@chakra-ui/icons'


export default function Home(props) {
  return (
	  <VStack spacing="100px">
	  <Box bgGradient='linear(to-tr, #E4F4E2, #86BA90)' px={["0px", "0px", "0px", "100px"]}>
	  <Hero2 />
	  </Box>

	  <Box>
	  <VStack spacing="15px">
	  <Heading as="h3" size="lg" align="center"><CheckCircleIcon color="blue.300" m="5px"/>New Tools, New Possibilities</Heading>
	  <Text width={["300px", "400px", "500px"]} align="center">Empowering citizens, public servants, journalists, researchers and developers with the data and tools they need to build a better Saskatchewan today.</Text>
	  <Image src="search_example.png" width="800px" rounded="1rem" shadow="2xl" />

          <SimpleGrid maxW="75rem" columns={[1, 2, 3]} gap={30} mb="25px" pt="50px">

            <Box w="100%" > <Link href="/search"><Heading size="md">Enhanced Searching</Heading></Link>
<Text py="10px">Unlock decades of legislative history with just a few clicks. Our search goes beyond the basics, allowing you to explore by party, constituency, and more, with instant results. Discover summaries, sentiments, and the heart of debates effortlessly. It's your fast track to understanding Saskatchewan's political landscape.</Text></Box>

            <Box w="100%" > <Link href="/postal"><Heading size="md">Accessible Tools for Citizens</Heading></Link>
<Text py="10px"> Peek into Saskatchewan's political heartbeat with just a postal code. Uncover what your representatives are championing in the legislature. It's politics made personal, and it starts with your curiosity.</Text></Box>

            <Box w="100%" > <Link href="/api_docs"><Heading size="md">Empowering Developers & Researchers</Heading></Link>
<Text py="10px">Step into the future of democracy with our open API. Forge new tools, uncover transformative insights, and lead the charge in redefining civic engagement. Join a community of forward-thinkers revolutionizing transparency and empowerment for all.</Text></Box>

          </SimpleGrid>

	  </VStack>
	  </Box>



	  <Box>
	  <VStack spacing="15px">
	  <Heading as="h3" size="lg"><RepeatIcon color="red.300"/>   Enriched by Artificial Intelligence</Heading>
	  <Text width={["300px", "400px", "500px"]} align="center">
	  Debates dating back over 70 years.  Cutting edge machine learning techniques.  Together at last producing new insight into Saskatchewan's rich political history.
	  </Text>
	  <Image src="dashboard.png" width="800px" rounded="1rem" shadow="2xl" />
	  </VStack>
	  </Box>

	  <Box>
	  <VStack spacing="15px">
	  <Heading as="h3" size="lg"><BellIcon color="green.300"/>   Always Up to Date</Heading>
	  <Text width={["300px", "400px", "500px"]} align="center">
	  Debate transcripts are added as soon as they become available.  Now including committee transcripts.
	  </Text>
	  <Image src="search.png" width="800px" rounded="1rem" shadow="2xl" />
	  </VStack>
	  </Box>



	  </VStack>

	  
  );
}
