import React from 'react';
import {
  ChakraProvider,
  Box,
  Flex,
  Spacer,
  theme,
} from '@chakra-ui/react';
import NavBar from './components/NavBar'; 
import About from './components/About';
import Search from './components/Search';
import Dashboards from './components/Dashboards';
import Api from './components/Api';
import Hansard from './components/Hansard';
import HansardCommittee from './components/HansardCommittee';
import Footer from './components/Footer';
import ApiDocs from './components/ApiDocs';
import WithSubnavigation from './components/Nav';
import Logo from './components/Logo';
import Home from './components/Home';
import Faq from './components/Faq';
import Contact from './components/Contact';
import Visualizations from './components/Visualizations';





import { Container } from './components/HoC';

import {
	  BrowserRouter as Router,
	  Switch,
	  Route,
	  Link
} from "react-router-dom";

import { extendTheme } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"
// Version 1: Using objects
const extendedT = extendTheme({
  styles: {
    global: {
      // styles for the `body`
      em: {
        color: "#000000",
        fontStyle: "normal",
        backgroundColor: "#fdf6e3",
      },
      // styles for the `a`
      a: {
        color: "teal.500",
        _hover: {
          textDecoration: "underline",
        },
      },
    },
  },
})


function App() {
  return (

    <ChakraProvider theme={extendedT}>
      <Flex direction="column" minHeight="100vh"> {/* Flex container to enable flex layout */}
        <WithSubnavigation />
        <Logo />
        <Box my={10} mx={5} flex="1"> {/* This Box wraps your main content and allows it to grow */}
          <Router>
            <Switch>

        <Route path="/about">
          <About />
        </Route>

        <Route path="/faq">
          <Faq />
        </Route>


	  
        <Route path="/visualizations">
          <Visualizations />
        </Route>

        <Route path="/search">
          <Search />
        </Route>

        <Route path="/api">
          <Api />
        </Route>

        <Route path="/api_docs">
          <ApiDocs />
        </Route>

        <Route path="/postal">
          <Container />
        </Route>

        <Route path="/contact">
          <Contact />
        </Route>





        <Route path="/hansard/:hansard_id" children=<Hansard />>
        </Route>

        <Route path="/hansard_committee/:hc_id" children=<HansardCommittee />>
        </Route>




        <Route path="/">
	  <Home />
        </Route>


      </Switch>
    </Router>


        </Box>
        <Spacer /> {/* This will push the footer to the bottom */}
        <Footer />
      </Flex>
    </ChakraProvider>




  );
}

export default App;
