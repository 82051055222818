import React, { useState, useEffect } from "react";
import {
  useParams,
  useLocation
} from "react-router-dom";
import MLASpeechBox from './MLASpeechBox';
import { Box, Text, Heading, Center, VStack, Spinner } from "@chakra-ui/react"


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function makeHansardPart(element) {

if(element.content === '') {

return ''

}

if(element.section_heading === true ) {

  if (element.content === 'DEBATES' || element.content === 'AND' || element.content === 'PROCEEDINGS' || element.content === 'LEGISLATIVE ASSEMBLY OF SASKATCHEWAN') {
  return ( '' ) }

  return ( <Heading size="lg" p={16}> {element.content} </Heading> )

}


if(element.speaker_of_the_assembly !== null) {

return (<MLASpeechBox content={element.content}
              name={element.mla_full_name}
              date={element.date} 
              member_id = {element.member_id}
              party={element.party}
              offices_held_during = {element.offices_held_during}
              electoral_division = {element.electoral_division}
	      extracted_title_first = {element.extracted_title_first}
	      extracted_title_second = {element.extracted_title_second}
	      extracted_initials = {element.extracted_initials}
	      extracted_last_name = {element.extracted_last_name}
              sequence = { element.sequence } >
</MLASpeechBox>)

}

return (<MLASpeechBox content={element.content} 
              name={element.mla_full_name} 
              date={element.date} 
              member_id = {element.member_id}  
              party={element.party}
              offices_held_during = {element.offices_held_during}
              electoral_division = {element.electoral_division}
              electoral_division = {element.electoral_division}
	      extracted_title_first = {element.extracted_title_first}
	      extracted_title_second = {element.extracted_title_second}
	      extracted_initials = {element.extracted_initials}
	      extracted_last_name = {element.extracted_last_name}
              sequence = { element.sequence } >
</MLASpeechBox>)


}



export default function HansardCommittee() {
        let query = useQuery();
	let id = useParams();
	let fetch_url = 'https://api.wheatpool.ca/rpc/committee_debate?hansard_committee_id=' + id['hc_id']

    const [state, setState ] = useState({loading: 'true'})

    useEffect(() => {

fetch(fetch_url)
.then(response => response.json())

.then(data => {
  setState({results: data}, );

})

.catch((error) => {
  console.error('Error:', error);
});


    }, [])


let results = state['results']
const Result = ({results}) => {

  let speeches = results.map((r, i) => makeHansardPart(results[i]));

  return speeches;

}



  if ( state['loading'] === 'true' ) {
    return (<Center><Spinner
	      thickness="4px"
	      speed="0.65s"
	      emptyColor="gray.200"
	      color="#86BA90"
	      size="xl"
	    /></Center>);
  }

  if (state['loading'] !== 'true' && query.get("seq") !== null) {
	 setTimeout(() => {document.getElementById(query.get("seq")).scrollIntoView() }, 1500);
         setTimeout(() => {document.getElementById(query.get("seq")).style.background = "#fdf6e3"; }, 1500);

  }
  

  return (
  <Center>
  <VStack mt="40px" spacing="25px" background="#fcfcfc" borderTop="1px" borderColor="gray.200" marginTop="-10px">
          <Box align="left" maxW="66%">
          <Heading size="xl" mt={10}>{ results[0]['committee_name'] } Committee Debate</Heading>
          <Heading size="md">{ results[0]['date_of_debate'] }</Heading>
          </Box>

          <Result results={results} />
</VStack>
</Center>

	  
  );
}
