import React from "react";
import { Box, Text, Heading, HStack, Center, Grid, GridItem, Button, IconButton, VStack, Input, Link, UnorderedList, ListItem } from "@chakra-ui/react";
import { SearchIcon } from '@chakra-ui/icons'


export default function About(props) {
  return (

<VStack align="center" spacing="24px">
<Box p={5} width={{ base: "100%", md: "75%", lg: "50%" }} border="1px" borderColor="gray.200" borderRadius="10px">
  <Heading mb={4} size="xl">About Us</Heading>
  <Text fontSize="xl" mb={3}>Welcome to Wheatpool Data Labs – your civic tech startup dedicated to enhancing democratic engagement. Our guiding principle, "Democratic Engagement through Innovation," drives us to transform how citizens interact with their government.</Text>

<Text fontSize="xl" mb={7}>Established just two years ago, our mission revolves around making Saskatchewan's Legislature more accessible to all. We firmly believe that every citizen deserves a clear and comprehensive understanding of their elected representatives and the legislative process, regardless of their background or financial capacity.</Text>

<Text fontSize="xl">At the core of our approach are three key strategies:</Text>

<UnorderedList m={7} fontSize="xl">
<ListItem mb={7}>Searchable Legislative Debates: We're breaking down barriers to access by making legislative debates easily searchable. No longer should vital discussions be locked away – our platform empowers you to delve into these conversations at your convenience.</ListItem>

<ListItem mb={7}>Enhanced Information and Summaries: Building upon the information disseminated by the Saskatchewan Legislature, we're elevating the level of comprehension by offering additional information and concise summaries. We're here to bridge the gap between complex legislative jargon and a citizen's need for clarity.</ListItem>

<ListItem mb={7}>Empowering Developers and Researchers: We're not just about creating tools for citizens; we're also creating opportunities for developers and researchers. Through our open API, talented minds can harness legislative data to build innovative tools that further democratize information and engagement. By fostering collaboration and innovation, we're expanding the frontiers of what's possible in democratic participation.</ListItem>
</UnorderedList>

<Text fontSize="xl" mb={7}>But we can't do this alone. We're calling upon individuals who share our vision of an informed and engaged citizenry. If the goals of Wheatpool Data Labs resonate with you, consider making a contribution. Your support will fuel our endeavors to push the boundaries of democratic engagement through innovative solutions.</Text>

<Text fontSize="xl" mb={7}>If you're curious about the inner workings of our site or the principles that guide us, dive into our FAQs for a more comprehensive understanding.</Text>

<Text fontSize="xl" mb={7}>Join us in this journey toward a more accessible, transparent, and engaging democratic landscape. At Wheatpool Data Labs, we're not just reimagining civic tech – we're reimagining democracy itself.</Text>

</Box>



</VStack>



	  
  );
}
