import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  Flex,
  Image,
  Heading,
  Stack,
  Text,
  HStack
} from "@chakra-ui/react"
import { SearchIcon, ViewIcon } from '@chakra-ui/icons'
import { Show, Hide } from '@chakra-ui/react'
 
export default function Hero2({
  title,
  subtitle,
  image,
  ctaLink,
  ctaText,
  ctaLink2,
  ctaText2,
  ...rest
}) {
  return (
    <Flex
      align="center"
      justify={{ base: "center", md: "space-around", xl: "space-between" }}
      direction={{ base: "column-reverse", md: "row" }}
      wrap="no-wrap"
      minH="70vh"
      px={8}
      mb={16}
      {...rest}
    >
      <Stack
        spacing={4}
        w={{ base: "60%", md: "40%" }}
        align={["center", "center", "flex-start", "flex-start"]}
      >
        <Heading
          as="h1"
          size="3xl"
          fontWeight="bold"
          color="primary.800"
          textAlign={["center", "center", "left", "left"]}
          mt={5}
        >
          {title}
        </Heading>
        <Heading
          as="h2"
          size="md"
          color="primary.800"
          opacity="0.8"
          fontWeight="normal"
          lineHeight={1.5}
          textAlign={["center", "center", "left", "left"]}
          pb="20px"
        >
          {subtitle}
        </Heading>
	<HStack>
        <Link style={{ textDecoration: 'none' }} to={ctaLink}>
          <Button
	    leftIcon={<SearchIcon />}
            color={'white'}
	    bg={'green.400'}
	    _hover={{bg: 'green.300',}}
            borderRadius="8px"
            py="4"
            px="4"
            lineHeight="1"
            size="md"
          >
            {ctaText}
          </Button>
       </Link>
       { /*  <Link style={{ textDecoration: 'none' }} to={ctaLink2}>
         <Button
	    leftIcon={<ViewIcon />}
	    variant="outline"
            color={'#486B4A'}
	    bg={'#C9F2CB'}
	    _hover={{bg: 'green.200',}}
            borderRadius="8px"
            py="4"
            px="4"
            lineHeight="1"
            size="md"
          >
            {ctaText2}
          </Button>
  </Link> */ }
	  </HStack>

        <Text
          fontSize="xs"
          mt={2}
          textAlign="center"
          color="primary.800"
          opacity="0.6"
        >
          No credit card required.
        </Text>
      </Stack>
        <Hide breakpoint='(max-width: 1000px)'>
       

        <Box
      as='video'
      
      autoPlay
      muted
      loop
      playsInline
      src='demo.mp4'
      poster='f.svg'
      alt='demo'
      objectFit='contain'
      w='50%'
      sx={{
        aspectRatio: '16/9'
      }}
  />
         </Hide>


    </Flex>
  )
}
 
Hero2.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  ctaText: PropTypes.string,
  /* ctaText2: PropTypes.string, */
  ctaLink: PropTypes.string,
  /* ctaLink2: PropTypes.string, */
}
 
Hero2.defaultProps = {
  title: "Legislative business at your finger tips.",
  subtitle:
    "We help the citizens of Saskatchewan understand what's happening in their legislature. Our advanced search makes it faster and easier than ever before to find out what was said in the legislature about topics that matter most to you.",
  image: "f.svg",
  ctaText: "Search The Debates Now",
/* ctaText2: "View Dashboards", */ 
  ctaLink: "/search",
  ctaLink2: "/dashboards",
}
