import React from "react";
import { Box, Text, Heading, HStack, Center, Grid, GridItem, Button, IconButton, VStack, Input, Link, UnorderedList, ListItem, Image} from "@chakra-ui/react";
import { SearchIcon } from '@chakra-ui/icons'


export default function Visualizations(props) {
  return (

<VStack align="center" spacing="24px">
<Box p={5} width="75%" border="1px" borderColor="gray.200" borderRadius="10px">
  <Heading mb={4} size="xl">Data Visualizations</Heading>

<Text fontSize="xl" mb={10}>We're not quite ready to show public visualizations and dashboards yet, but we're working on it!  We'd love for you to get in touch with us if you have any specific things you'd like to see presented visually in a dashboard or infographic.  </Text>
<Image src='dashboard.png' />

</Box>



</VStack>



	  
  );
}
