import React from "react";
import { Box, Text, Heading, HStack, Center, Link} from "@chakra-ui/react";
export default function Logo(props) {
  return (
	            <HStack m={5} pb={2}>
	            <Box background="#86BA90" p={6} width="8rem" align="left">
	            <Link style={{ textDecoration: 'none' }} href="/">
	            <Center>
	            <Text fontSize="6xl" color="white">W</Text>
	            </Center>
	            </Link>
	            </Box>
	            
	            <Box maxW="32rem" m={8} pl={5}>
	            <Heading size="2xl">Wheatpool Data Labs</Heading>
	            <Text fontSize="xl">Democratic Engagement through Innovation</Text>
	            </Box>

	  </HStack>
  );
}
