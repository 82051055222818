import React from "react";
import moment from "moment";

import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";

import {
	  ErrorBoundary,
	  Facet,
	  SearchProvider,
	  SearchBox,
	  Results,
	  PagingInfo,
	  ResultsPerPage,
	  Paging,
	  Sorting,
	  WithSearch
} from "@elastic/react-search-ui";
import {
	  BooleanFacet,
	  Layout,
	  SingleSelectFacet,
	  SingleLinksFacet
} from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import ResultView from "./ResultView";
import { Spinner, Flex, VStack, Input, Button } from "@chakra-ui/react";



const ResultsView = ({ children }) => (

		      <VStack align="left" width="100%" spacing="24px"
		      py="5px">
          {children}            
		      </VStack>
		    );


const SORT_OPTIONS = [
  {
    name: "Relevance",
    value: "",
    direction: ""
  },
  {
    name: "Newest First",
    value: "date_of_debate",
    direction: "desc"
  },
  {
    name: "Oldest First",
    value: "date_of_debate",
    direction: "asc"
  }
];

const connector = new AppSearchAPIConnector({
  searchKey: "search-vabobyuc46c1a33dq6f5wpgh",
  engineName: "wheatpool",
  endpointBase: "https://search.wheatpool.ca"
});

const config = {
  alwaysSearchOnInitialLoad: true,
  searchQuery: {
    result_fields: {
      mla_full_name: {
        raw: {}
      },
      extracted_title_first: {
        raw: {}
      },
      extracted_title_second: {
        raw: {}
      },
      extracted_initials: {
        raw: {}
      },
      extracted_last_name: {
        raw: {}
      },
      date_of_debate: {
        raw: {}
      },
      wheatpool_path: {
        raw: {}
      },
      party: {
        raw: {}
      },
      offices_held_during: {
        raw: {}
      },
      mla_id: {
        raw: {}
      },
      committee_name: {
        raw: {}
      },
      electoral_division: {
        raw: {}
      },
      sentiment: {
        raw: {}
      },
      topics: {
        raw: {}
      },
      summary: {
        raw: {}
      },
      tone: {
        raw: {}
      },
      legislation: {
        raw: {}
      },

      content: {
        snippet: {
	  size: 500,
	  fallback: true }
      }
    },
    disjunctiveFacets: ["age_at_time_spoken", "electoral_division", "date_of_debate", "word_count", "committee_name", "party", "offices_held_during", "indigenous"],
    facets: {
      committee_name: { type: "value" },
      legislature: { type: "value" },
      session: { type: "value" },
      party: { type: "value" },
      gender: { type: "value" },
      indigenous: { type: "value" },
      offices_held_during: { type: "value" },
      electoral_division: { type: "value", size: 250 },
      sentiment: { type: "value" },
      mla_full_name: { type: "value", size: 250 },
      age_at_time_spoken: {
        type: "range",
        ranges: [
          { from: -1, name: "Any" },
          { from: 0, to: 35, name: "Young" },
          { from: 36, to: 60, name: "Middle Aged" },
          { from: 61, name: "Senior" }
        ]
      },
      word_count: {
        type: "range",
        ranges: [
          { from: 0, to: 1000, name: "Short" },
          { from: 1000, to: 5000, name: "Medium" },
          { from: 5000, name: "Long" }
        ]
      },
      date_of_debate: {
        type: "range",

        ranges: [
          {
            from: moment().subtract(5, "years").toISOString(),
            name: "Within the last 5 years"
          },
          {
            from: moment().subtract(10, "years").toISOString(),
            to: moment().subtract(5, "years").toISOString(),
            name: "5 - 10 years ago"
          },
          {
            from: moment().subtract(20, "years").toISOString(),
            to: moment().subtract(10, "years").toISOString(),
            name: "10 - 20 years ago"
          },

          {
            to: moment().subtract(20, "years").toISOString(),
            name: "More than 20 years ago"
          }
        ]
      },
    }
  },
  autocompleteQuery: {
    results: {
      resultsPerPage: 5,
      result_fields: {
        content: {
          snippet: {
            size: 100,
            fallback: true
          }
        },
        wheatpool_path: {
          raw: {}
        }
      }
    },
    suggestions: {
      types: {
        documents: {
          fields: ["content"]
        }
      },
      size: 4
    }
  },
  apiConnector: connector
};



export default function Search(props) {

 return (
    <SearchProvider config={config}>
      <WithSearch mapContextToProps={({ wasSearched, isLoading }) => ({ wasSearched, isLoading })}>
        {({ wasSearched, isLoading }) => {
          return (
            <div >
              <ErrorBoundary>
                <Layout
                  header={
                    <SearchBox
                      autocompleteMinimumCharacters={4}
                      //searchAsYouType={true}
                      autocompleteResults={{
                        linkTarget: "_blank",
                        sectionTitle: "Results",
                        titleField: "content",
                        urlField: "wheatpool_path"
                      }}
                      autocompleteSuggestions={true}
                      debounceLength={0}

  inputView={({ getAutocomplete, getInputProps, getButtonProps }) => (
    <>
      <Flex mt="-45px" width="100%" >
        <Input size="lg" width="100%"
          {...getInputProps({
            placeholder: "Try quoting phrases for more precise results, e.g. \"Saskatchewan Libraries\""
          })}
        />
        {getAutocomplete()}
      <Button colorScheme="blue" size="lg"
        {...getButtonProps({
          "data-custom-attr": "some value"
        })}
      >{ isLoading ? <Spinner /> : "Search" }</Button>
</Flex>
    </>
  )}




                    />
                  }
                  sideContent={
                    <div>
                      {wasSearched && (
                        <Sorting label={"Sort by"} sortOptions={SORT_OPTIONS} />
                      )}
                      <Facet
                        field="committee_name"
                        label="Committee"
                        filterType="any"
                        isFilterable={true}
                      />

                    <Facet
                        field="mla_full_name"
                        label="MLA Name"
                        filterType="any"
                        isFilterable={true}
                      />

                      <Facet
                        field="electoral_division"
                        label="Electoral Division"
                        filterType="any"
                        isFilterable={true}
                      />

                      
                      <Facet
                        field="sentiment"
                        label="Sentiment"
                        filterType="any"
                      />

                      <Facet
                        field="party"
                        label="Party"
                        filterType="any"
                      />

                      <Facet
                        field="gender"
                        label="Gender"
                        filterType="any"
                      />

                      <Facet
                        field="indigenous"
                        label="Indigenous"
                        filterType="any"
                      />

                      <Facet
                        field="date_of_debate"
                        label="Date of Debate"
                        filterType="any"
                      />
                      <Facet
                        field="word_count"
                        label="Length of Speech"
                        filterType="any"
                      />
                      <Facet
                        field="offices_held_during"
                        label="Minister of / Responsible for"
                        filterType="any"
                      />
                    </div>
                  }
                  bodyContent={
                    <Results
		      view={ResultsView}
                      titleField="mla_full_name"
                      urlField="wheatpool_path"
	              resultView={ResultView}
                    />
                  }
                  bodyHeader={
                    <React.Fragment>
                      {wasSearched && <PagingInfo />}
                      {wasSearched && <ResultsPerPage />}
                    </React.Fragment>
                  }
                  bodyFooter={<Paging />}
                />
              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}

