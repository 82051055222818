import React from "react";
import { Box, Text, Heading, HStack, Center, Grid, GridItem, Button, IconButton, VStack, Input, Link, UnorderedList, ListItem } from "@chakra-ui/react";
import { SearchIcon } from '@chakra-ui/icons';
import { Code } from '@chakra-ui/react'


export default function ApiDocs(props) {
  return (

<VStack align="center" spacing="24px">
<Box p={5} width={{ base: "100%", md: "75%", lg: "50%" }} border="1px" borderColor="gray.200" borderRadius="10px">
  <Heading mb={4} size="xl">Wheatpool Data Labs API Quick Start Guide</Heading>

<Box>
  <Text fontSize="lg" mb="6">Welcome to the Wheatpool Data Labs API Quick Start Guide. This platform offers unparalleled access to the discussions happening in Saskatchewan's Legislature, enabling a deep dive into historical records, current political narratives, and the evolution of legislative discourse.</Text><Text fontSize="lg" mb="6">Ideal for researchers, journalists, and civic technologists, this guide demonstrates new and exciting methods for creating data-driven insights and analysis. Start using the API today to unlock new perspectives and contribute to informed public discourse.</Text>

  <Heading as="h2" size="lg" mt="6" mb="4">Getting Started</Heading>

  <Text mb="4">Start by ensuring <Code>curl</Code> is installed on your machine. Next, explore the examples below by running them yourself.  Try modifying the parameters and see what happens!  Once you've successfully run a few examples you can navigate to the <Link href="/api" color="green">Wheatpool Data Labs API Reference</Link> for a comprehensive list of available endpoints and parameters.</Text>

<Text mb="4">After you've mastered the basics, check out the <Link href="https://postgrest.org/en/stable/references/api/tables_views.html" color="green">PostgREST tables and views documentation</Link> for a deep dive into all the possible ways you are able to construct queries against the Wheatpool Data Labs API.</Text>

  <Heading as="h2" size="md" mt="6" mb="4">Insights into Young Voices</Heading>
  <Text mb="4">Discover the impact of young voices in Saskatchewan politics through this API request. The parameter '<Code>age_at_time_spoken=lte.30</Code>' filters for contributions by Members of the Legislative Assembly (MLAs) who were 30 years old or younger at the time of speaking, where '<Code>lte</Code>' stands for 'less than or equal to'. The '<Code>limit=5</Code>' and '<Code>order=date_of_debate.desc</Code>' narrows down the results to the five most recent entries, highlighting the active role of younger politicians in shaping policy and debate. </Text>
  <Code p="4" width={{ base: "100%", md: "75%", lg: "50%" }} bg="gray.100" mb="6">
    curl -X GET "https://api.wheatpool.ca/debate_filter?age_at_time_spoken=lte.30&limit=5&order=date_of_debate.desc" -H "accept: application/json"
  </Code>


  <Heading as="h2" size="md" mt="6" mb="4">Fetching Debates on Hot Topics</Heading>
  <Text mb="4">In this example we will explore how climate change is discussed in parliamentary debates. The '<Code>ilike</Code>' modifier performs a case-insensitive search, while asterisks act as wildcards, capturing any content related to "climate change".  We'll use the <Code>tone</Code> parameter to filter for discussions marked by immediacy.
</Text>
  <Code p="4" width={{ base: "100%", md: "75%", lg: "50%" }} bg="gray.100" mb="6">
    curl -X GET "https://api.wheatpool.ca/debate_filter?content=ilike.*climate%20change*&tone=ilike.*urgent*&limit=10" -H "accept: application/json"
  </Code>

  <Heading as="h2" size="md" mt="6" mb="4">Innovative Women</Heading>
  <Text mb="4">This API call brings to light the contributions of women in areas of innovation and technology. By utilizing the '<Code>or</Code>' clause in '<Code>topics.ilike.*innovation*,topics.ilike.*technology*</Code>', it broadens the search to include discussions on either innovation or technology.  This example demonstrates how to structure queries to capture a range of topics, providing users with flexibility in data exploration.</Text>
  <Code width={{ base: "100%", md: "75%", lg: "50%" }} p="4" bg="gray.100" mb="6">
curl -X GET "https://api.wheatpool.ca/debate_filter?gender=eq.female&or=(topics.ilike.*innovation*,topics.ilike.*technology*)&limit=5" -H "accept: application/json"

  </Code>

  <Heading as="h2" size="md" mt="6" mb="4">Indigenous Voices in Debates</Heading>
  <Text mb="4">Let's explore a slightly more complicated request.  In this example, we'll uncover the narratives of indigenous MLAs within the legislature through a tailored API call. It filters debates for indigenous participation, focusing on discussions related to reconciliation and women's perspectives from 2020. To accomplish this we'll leverage the <Code>not.is.null</Code> filter on the <Code>indigenous</Code> parameter to ensure only debates with indigenous representation are selected. Additionally, the dual use of <Code>date_of_debate</Code> parameters sets a specific time frame, capturing discussions within the chosen period.  Lastly, we'll once again use the <Code>content</Code> parameter to filter for discussions containing the term 'reconciliation'. </Text>
  <Code p="4" width={{ base: "100%", md: "75%", lg: "50%" }} bg="gray.100" mb="6">
    {"curl -X GET \"https://api.wheatpool.ca/debate_filter?indigenous=not.is.null&date_of_debate=gte.2020-01-01&date_of_debate=lte.2020-12-31&content=ilike.*reconciliation*&gender=eq.female&limit=5\" -H \"accept: application/json\""}
  </Code>

  <Heading as="h2" size="md" mt="6" mb="4">Dive into a Full Debate</Heading>
  <Text mb="4">You can at any time retrieve the full transcript for any debate you came across in the previous examples.  Each snippet we retrieved earlier returned a field called <Code>wheatpool_hansard_id</Code>.  We'll supply the value of this field to the <Code>/rpc/debate</Code> endpoint and in return we'll recieve the full text of the Hansard that the snippet occured in.</Text>
  <Code p="4" width={{ base: "100%", md: "75%", lg: "50%" }} bg="gray.100" mb="6">
    {"curl -X POST \"https://api.wheatpool.ca/rpc/debate\" -H \"Content-Type: application/json\" -d '{\"document_id\": [wheatpool_hansard_id_value]}'"}
  </Code>

</Box>



</Box>



</VStack>



	  
  );
}
